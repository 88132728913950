$(document).ready(function(){

	// Close and open stuff using ID
	(function(){
		var show = function (elem) {
			elem.style.display = 'block';
		};
		
		var hide = function (elem) {
			elem.style.display = 'none';
		};
		
		var toggle = function (elem) {
			if (window.getComputedStyle(elem).display === 'block') {
				hide(elem);
				return;
			}
			show(elem);
		};
		document.addEventListener('click', function (event) {

			if (!event.target.classList.contains('toggle')) return;

			// if (event.target.classList.contains('dontToggle')) return;
			
			event.preventDefault();
		
			var content = document.querySelector(event.target.hash);
			if (!content) return;
		
			toggle(content);
		}, false);	
	})();
});
